<template>
	<header class="site-header white">
		<div class="container">
			<div class="header-row">
				<div class="header-logo">
					<router-link to="/home"><div class="logo"></div></router-link>
				</div>
				<button class="header-menu-btn"></button>
				<nav class="header-menu">
					<button class="header-menu-btn close"></button>
					<ul>
						<li class="d-block d-md-none"><router-link to="/home">Home</router-link></li>
						<!-- <li><router-link to="/presale">PreSale</router-link></li> -->
						<li><router-link to="/home/feature">Feature</router-link></li>
						<!-- <li><router-link to="/home/stat">Stat</router-link></li> -->
						<li><router-link to="/home/mining-center">Mining Center</router-link></li>
						<li class="has-sub">
							<a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Docs</a>
							<div class="dropdown-menu">
								<a class="dropdown-item" href="/BTCTH_WhitePaper_Eng_v.1.0.2.pdf" target="blank">Whitepaper</a>
								<!-- <a class="dropdown-item" href="#">Audit Report</a> -->
							</div>
						</li>
						<li class="d-block d-md-none"><a href="/BTCTH_WhitePaper_Eng_v.1.0.2.pdf" target="blank">Whitepaper</a></li>
						<!-- <li class="d-block d-md-none"><a href="#">Audit Report</a></li> -->
					</ul>
					<div class="header-user" v-if="walletAddress > ''">
						<div class="user-thumb">
							<img src="img/img-user-account.png" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<div class="dropdown-menu">
								<router-link class="dropdown-item" to="/mypage">My Page</router-link>
								<a class="dropdown-item" @click="disconnectWallet">Log Out</a>
							</div>
						</div>
						<a disabled class="btn btn-light">{{ truncate(walletAddress, 12) }}</a>
					</div>

					<!-- <div class="header-func">
						<div class="header-wallet" v-if="walletAddress > ''">
							<a href="#" class="btn btn-secondary">Launch App</a>
						</div>
						<div class="header-app" v-else>
							<a class="btn btn-primary" @click="connectWallet">Connect Wallet</a>
						</div>
					</div> -->

				</nav>
			</div>
		</div>
	</header>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import { walletComputed, walletMethods } from '@/store/wallet-helper'
import PrjConstants from '@/config/constants.js'

export default {
  name: 'MainHeader',
  props: {
  },
  computed: {
    ...walletComputed,

	prjConst(field) {
		if (PrjConstants && PrjConstants[`${field}`]) return PrjConstants[`${field}`];
		return '';
	},
    currentRoute: {
      get() {
        // console.log("Current route = ", this.$route.name);
        return this.$route.name;
      },
    },
  },
  async mounted() {
  },
  methods: {
    ...walletMethods,

    truncate(v, stop, clamp) {
      if (!v) return "";
      return v.slice(0, stop) + (stop < v.length ? clamp || "..." : "");
    },
  },  
}
</script>

