import { ethers } from "ethers";
const { utils } = ethers;

import detectEthereumProvider from '@metamask/detect-provider';
import Contracts from "@/contracts";
let { erc20Artifact } = Contracts;
import ProjectConstants from '@/config/constants';

const ROPSTEN_ID = '0x3';
const BSC_ID = '0x38';
const BSC_TESTNET_ID = '0x61';
const HARDHAT_ID = '0x7a69';

let network = ProjectConstants.network;
if (process.env.NETWORK) network = process.env.NETWORK;

let DAPP_CHAIN_ID = ProjectConstants.chainId;

if (network == 'bsc-mainnet') DAPP_CHAIN_ID = BSC_ID;
else if (network == 'bsc-testnet') DAPP_CHAIN_ID = BSC_TESTNET_ID;
else if (network == 'ropsten') DAPP_CHAIN_ID = ROPSTEN_ID;
else {
    // network == 'localhost'
    DAPP_CHAIN_ID = HARDHAT_ID;
}

const KEY_CONNECTED = 'connected';

const provider = window.ethereum;
let hasAccountRequest = null;    // true if connect wallet request 
let walletProvider = null;
let walletConnected = false;       // true, if user actually try to connected. false, if user HAS disconnected.

export const state = {
    ethereumDetected: false,
    connected: false,
    address: '',
    chainId: 0,
    balance: '',
    tokens: [],         // { contract, address, symbol, name, decimals, totalSupply, balance }
}

function findToken(symbol) {
    for (let i=0; i<state.tokens.length; i++) {
        if (state.tokens[i].symbol == symbol) {
            return state.tokens[i];
        }
    }
    return null;
}

export const getters = {
    getToken: (state) => (symbol) => {
        return findToken(symbol);
    },

    getTokenBalance: (state) => (symbol) => {
        // console.log(`== wallet : getTokenBalance('${symbol}')`)
        let token = findToken(symbol)
        if (token) {
            console.log(`== wallet : getTokenBalance('${symbol}') , token found`, token.balance)
            return token.balance;
        }
        return '0'
    }
}

export const mutations = {
    SET_DETECTED(state, value) {
        state.ethereumDetected = value;
    },
    SET_CONNECTED(state, value) {
        state.connected = value;
        if (value == false) state.address = '';
    },
    SET_ADDRESS(state, addr) {
        state.address = addr;
    },
    SET_BALANCE(state, balance) {
        state.balance = balance;
    },
    SET_CHAIN_ID(state, chainId) {
        state.chainId = chainId;
    },
    SET_ACCOUNT(state, {address, signer, balance, ready}) {
        state.address = address;
        state.signer = signer;
        state.balance = balance;

        walletConnected = true;
        localStorage.setItem(KEY_CONNECTED, '1');
    },
    RESET_WALLET(state) {
        state.connected = false
        state.address = ''
        state.balance = ''
        state.signer = null

        walletConnected = false;
        localStorage.setItem(KEY_CONNECTED, '0');
    },
    ADD_TOKEN(state, token) {
        state.tokens.push(token);
    },
    UPDATE_TOKEN_BALANCE(state, { symbol, balance }) {
        let token = findToken(symbol);
        if (token) {
            token.balance = balance;
        }
    }
}

export const actions = {
    async init({ commit, state, dispatch }) {
        // console.log('== wallet : init')

        const detectedProvider = await detectEthereumProvider();

        if (detectedProvider) {
            // console.log('== wallet : Ethereum detected!')

            // check if wallet connected before.
            let hasConnected = localStorage.getItem(KEY_CONNECTED);
            if (hasConnected && hasConnected == '1') {
                walletConnected = true;
            }
            else {
                walletConnected = false;
            }

            commit('SET_DETECTED', true);

            detectedProvider.on("accountsChanged", onAccountChanged);
            detectedProvider.on("chainChanged", onChainChanged);
            detectedProvider.on("disconnect", onDisconnected);

            // console.log('== wallet init : waiting event, connection state =', detectedProvider.isConnected())
            if (detectedProvider.isConnected()) {
                onWalletAvailable();
            }

            detectedProvider.on("connect", onWalletAvailable);
        } else {
            // if the provider is not detected, detectEthereumProvider resolves to null
            // TODO: 
            console.error('Please install MetaMask!')
        }

        async function onWalletAvailable(connectionInfo) {
            // console.log('== ethereum : connect')

            if (provider.isConnected()) {
                try {
                    let chainId = await provider.request({
                        method: 'eth_chainId'
                    })

                    onChainChanged(chainId);
                }
                catch (e) {
                    console.log('onWalletAvailable-error', e)
                }
            }
        }

        async function onChainChanged(chainId) {
            // console.log('== ethereum : chainChanged')

            setChain(commit, chainId);
        }

        async function onAccountChanged(accounts) {
            // console.log('== ethereum : accountChanged')

            setAccounts(commit, accounts);
        }

        function onDisconnected(error) {
            // console.log('== ethereum : disconnect', error)

            resetWallet(commit)
        }
    },
    setConnected({ commit }, { isConnected, addr }) {
        // console.log("Wallet setConnected :", isConnected, addr)

        if (isConnected) {
            commit('SET_CONNECTED', true)
            if (addr) commit('SET_ADDRESS', addr);
        }
        else {
            commit('SET_CONNECTED', false);
            commit('SET_ADDRESS', '');
        }
    },
    async connect({ commit }) {
        console.log('Connect wallet called : chainId = ', state.chainId);

        walletConnected = true;
        localStorage.setItem(KEY_CONNECTED, '1');
        hasAccountRequest = true

        if (state.chainId == DAPP_CHAIN_ID) {
            // already connected and selected

            // let accounts = await provider.request({ method: "eth_accounts" });

            // setAccounts(commit, accounts);

            // if (accounts.length == 0 && needRequest) {
            //     await requestAccounts()
            // }
            // return
            setChain(commit, state.chainId);
            return;
        }

        console.log('--- calling switch chain !!')

        // change chain first
        // after chain changed, request wallet again
        await switchChain(DAPP_CHAIN_ID)
    },
    async disconnect({ commit }) {

        console.log('--- action disconnect');

        walletConnected = false;
        localStorage.setItem(KEY_CONNECTED, '0');
        resetWallet(commit);
    },
    async updateBalance({ commit }) {
        updateBalance(commit);
    },
    async updateTokenBalance({commit}) {
        if (state.address > '') {
            for (let i=0; i<state.tokens.length; i++) {
                let nBalance = await state.tokens[i].contract.balanceOf(state.address);
                let balance = utils.formatEther(nBalance);
                commit('UPDATE_TOKEN_BALANCE', { symbol: state.tokens[i].symbol, balance });

                console.log(`== wallet : token[${state.tokens[i].symbol}] balance =`, state.tokens[i].balance)
            }
        }
    },
    async addToken({ commit }, address) {
        let walletProvider = new ethers.providers.Web3Provider(window.ethereum);

        let erc20 = new ethers.Contract(
          address,
          erc20Artifact.abi,
          walletProvider
        );
        
        console.log('ERC 20 -- token :', erc20)
        let balance = '';
        if (state.address > '') {
            let nBalance = await erc20.balanceOf(state.address);
            balance = utils.formatEther(nBalance);
        }

        let token = {
            contract: erc20,
            address,
            symbol: await erc20.symbol(),
            name: await erc20.name(),
            decimals: await erc20.decimals(),
            totalSupply: await erc20.totalSupply(),
            balance
        }
        commit('ADD_TOKEN', token);
    },
}

// --------------------------------------------------------------------
//   onBeforeUnmount(() => {
//     if (provider) {
//       provider.removeListener("connect", onWalletAvailable);
//       provider.removeListener("accountsChanged", onAccountChanged);
//       provider.removeListener("chainChanged", onChainChanged);
//       provider.removeListener("disconnect", disconnected);
//     }
//   })

async function setChain(commit, chainId) {
    // console.log("== wallet: setChainId:", chainId);

    // state.chainId = chainId;
    commit('SET_CHAIN_ID', chainId);

    // user disconnected or initial state
    if (walletConnected == false) {
        // console.log('============ skip setChain : walletConnected =', walletConnected)
        return;
    }

    let needRequest = hasAccountRequest;
    hasAccountRequest = false

    // check
    if (chainId == DAPP_CHAIN_ID) {
        try {
            let accounts = await provider.request({ method: "eth_accounts" });

            // already connected and selected
            setAccounts(commit, accounts);

            if (accounts.length == 0 && needRequest) {
                await requestAccounts()
            }
        }
        catch (e) {
            console.log('chainChanged-error', e)
        }
    }
    else {
        resetWallet(commit);
    }    
}

async function setAccounts(commit, accounts) {
    // console.log("== wallet : setAccounts() :", accounts);

    if (null == walletProvider) {
        // console.log('== wallet : setAccount(),  new eth provider ')
        walletProvider = new ethers.providers.Web3Provider(window.ethereum);
        state.provider = walletProvider
    }

    if (accounts.length > 0) {
        console.log('accounts : ', accounts);

        let address = accounts[0];
        let gwei = await walletProvider.getBalance(address);

        commit('SET_ACCOUNT', {
            address,
            signer: walletProvider.getSigner(),
            balance: utils.formatUnits(gwei, "ether"),
            ready: true
        });
    } else {
        resetWallet(commit);
    }
}

async function updateBalance(commit) {
    // console.log("--- updateAccount :");

    if (null == walletProvider) {
        console.log('== wallet : updateBalance(), new eth provider ')
        walletProvider = new ethers.providers.Web3Provider(window.ethereum);
        state.provider = walletProvider
    }

    if (state.address > '') {
        let address = state.address;
        let gwei = await walletProvider.getBalance(address);
        commit('SET_BALANCE', utils.formatUnits(gwei, "ether"));
    }
}

function resetWallet(commit) {
    commit('RESET_WALLET');
}

async function switchChain(chainId) {
    if (!provider) {
        window.alert("Install wallet (Metamask) first");
        return false;
    }

    if (state.chainId != chainId) {
        console.log("--- switching to chain : ", chainId, ' old chain = ', state.chainId);

        try {
            let res = await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: chainId }],
            });
            console.log('Chain req response ', res)
            return true;

        } catch (switchError) {
            if (switchError.code === 4902) {
                // TODO: addChain()
            } else {
                if (switchError.code === 4001) {
                    console.log('User rejected switch chain to : ', chainId)
                }
                else {
                    console.log("Ethereum change network error", switchError);
                }
            }
            return false;
        }
    }
    else {
        console.log("--- switchChain(chainId) : chainId =  ", chainId);
        return true
    }
}

async function requestAccounts() {
    try {
        let accounts = await provider.request({
            method: "eth_requestAccounts",
        });

        console.log('===== requestAccounts() .. ', accounts)

        // accountChanged(accounts)
    } catch (e) {
        if (e.code === 4001) {
            console.log('User rejected to connect wallet')
        }
        else {
            console.error('requestAccounts', e);
        }
    }
}

