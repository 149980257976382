<template>
  <div>
    <MainHeader></MainHeader>
    <router-view></router-view>
    <MainFooter></MainFooter>
		<div class="clearfix" id="blockchain-spinner" :class="spinning == true ? 'show-spinner' : 'hide-spinner'">
			<div class="spinner-border text-danger float-right" role="status">
			</div>
      <!-- <div class="spinner-grow text-danger float-right" role="status">
        <span class="sr-only">Loading...</span>
      </div> -->
		</div>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import { storeComputed } from "@/store/store-helper";

export default {
  name: 'App',
  components: {
    MainHeader,
    MainFooter,
  },
  computed: {
    ...storeComputed,
  },
  watch: {
    spinning() {
      console.log('.... Spin changed :', this.spinning)
    }
  }
}
</script>

<style scoped>
#blockchain-spinner {
      position: fixed; /* Sit on top of the page content */
      width: 100%; /* Full width (cover the whole page) */
      height: 100%; /* Full height (cover the whole page) */
      top: 0;
      left: 0;
      /* background-color: rgba(60, 138, 255, 0.1); /* Black background with opacity */
      opacity: 0.5;
      z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
      /* cursor: progress; /* Add a pointer on hover */
}
.show-spinner {
    display: block;
}
.hide-spinner {
    display: none;
}
</style>

