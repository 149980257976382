


import ProjectConstants from '@/config/constants';

const isProduction = process.env.NODE_ENV == 'production' ? true : false;

let network = ProjectConstants.network;
if (process.env.NETWORK) network = process.env.NETWORK;

let contractAddress = require(`@/contracts/${network}/contract-address.json`);

import erc20Artifact from "@/contracts/ERC20.json";
import crowdsaleArtifact from "@/contracts/CrowdSaleClaimableRewardable.json";
import farmArtifact from "@/contracts/MiningFarm.json";
import faucetArtifact from "@/contracts/TokenFaucet.json";

// see to : network name  of 'hardhat.config.js' in btcst.smartcontract project
//
if (isProduction) {
    network = 'bsc-mainnet';
    // faucetArtifact = {};        // NOT supported in production
}

// console.log('Contract address :', contractAddress)

export default {
    network,
    contractAddress,
    erc20Artifact,
    crowdsaleArtifact,
    farmArtifact,
    faucetArtifact,
}