import { createStore } from 'vuex'
import modules from './modules'
import dispatchActionForAllModules from './dispatch-action-for-all-modules'

// import getWeb3 from '@/utils/get-web3'

let isStrict = true
if (process && process.env && process.env.NODE_ENV !== 'production')  {
    isStrict = false
}

export default createStore({
    state: {
        version: '0.1',
        spinning: false,
    },
    mutations: {
        SET_SPIN(state, value) {
            state.spinning = value ? true : false;
        }
    },
    actions: {
        async init({ commit, state, dispatch }) {
        },
        setSpin({ commit }, isSpin) {
            commit('SET_SPIN', isSpin)
        }
    },
    modules,
    strict: isStrict
})

// Automatically run the `init` action for every module,
// if one exists.
dispatchActionForAllModules('init')
