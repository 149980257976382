import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/home/:loc',
        name: 'home-loc',
        component: Home
    },
    {
        path: '/faq',
        name: 'fqq',
        component: () => import('@/views/Faq.vue')
    },
    // {
    //     path: '/r/:refId',
    //     name: 'home-ref',
    //     component: Home
    // },
    // {
    //     path: '/presale',
    //     name: 'presale',
    //     component: () => import('@/views/Presale.vue')
    // },
    // {
    //     path: '/mypage',
    //     name: 'mypage',
    //     component: () => import('@/views/Mypage.vue')
    // },
]

const router = createRouter({
    history: createWebHashHistory('/'),  // or createWebHashHistory('/base-directory/')
    routes,
    linkActiveClass: 'active'
})

export default router
