<template>
  <div class="site-body" id="home">
    <section class="main-section main-section-1">
      <div class="container">
        <div class="cont-wrap">
          <div class="text-cont">
            <h1>Bitcoin<br />TeraHash</h1>
            <div class="row align-items-center mb-3 mb-md-4">
              <div class="col-auto"><img src="img/img-check-header.svg" /></div>
              <div class="col"><h5>Daily Bitcoin Rewards through DeFi</h5></div>
            </div>
            <div class="row align-items-center">
              <div class="col-auto"><img src="img/img-check-header.svg" /></div>
              <div class="col">
                <h5>Easy Entry and Exit of Bitcoin Mining</h5>
              </div>
            </div>
          </div>
          <div class="img-cont">
            <img src="img/img-img-billy-header.png" />
          </div>
          <div class="sns-wrap">
            <a :href="prjConst('twitter')" target="_blank"><img src="img/logo-sns-twitter.svg"></a>
            <a :href="prjConst('telegram_chat')" target="_blank"><img src="img/logo-sns-telegram.svg"></a>
            <a :href="prjConst('medium')" target="_blank"><img src="img/logo-sns-medium.svg"></a>
            <a :href="prjConst('github')" target="_blank"><img src="img/logo-sns-github.svg"></a>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="main-section main-section-2">
      <div class="container">
        <div class="row align-items-center cont-wrap padding-1">
          <div class="col-12 col-md">
            <div class="row align-items-center">
              <div class="col-auto d-none d-md-block">
                <svg
                  class="circle-progress"
                  viewBox="0 0 272 272"
                  data-percent="10"
                >
                  <circle cx="137" cy="137" r="100"></circle>
                  <circle cx="137" cy="137" r="100"></circle>
                </svg>
              </div>
              <div class="col">
                <p class="round">Presale Round 1/5</p>
                <p class="count">$1.2 / 100,000 BTCTH</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-auto">
            <div class="count-down-wrap">
              <div>
                <p class="tit">Day</p>
                <p class="num">13</p>
              </div>
              <div>
                <p class="tit">Hours</p>
                <p class="num">09</p>
              </div>
              <div>
                <p class="tit">Minutes</p>
                <p class="num">23</p>
              </div>
              <div>
                <p class="tit">Seconds</p>
                <p class="num">45</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md">
            <div class="row justify-content-center justify-content-md-end">
              <a href="#" class="btn btn-primary btn-lg w-180 btn-shadow"
                >Buy BTCTH</a
              >
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- <section class="main-section main-section-3">
      <div class="container container-max-lg">
        <h2>Sold Tokens</h2>
        <div class="sold-tokens-wrap">
          <div class="row text-wrap">
            <div class="col-auto">
              <p>BTCTH Presale / Round 1</p>
            </div>
            <div class="col-auto">
              <h5>0 / 1,000,000 BTCTH (50.4%)</h5>
            </div>
          </div>
          <div class="sold-tokens-progress-wrap">
            <div class="sold-tokens-progress" style="width: 50%"></div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="main-section main-section-4" id="feature">
      <div class="container container-max-lg">
        <h2>BTCHT’S feature</h2>
        <div class="row align-itmes-stretch padding-3 my-3 my-sm-5">
          <div class="col-12 col-sm-6 my-3">
            <div class="img-box">
              <div class="img-box-img">
                <img src="img/img-feature-1.svg" />
              </div>
              <div class="img-box-title type-1">
                <h5>Decentralized</h5>
              </div>
              <div class="img-box-description">
                <p>Access an bitcoin mining market directly on-chain</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 my-3">
            <div class="img-box">
              <div class="img-box-img">
                <img src="img/img-feature-2.svg" />
              </div>
              <div class="img-box-title type-2">
                <h5>Permanent Hash Power</h5>
              </div>
              <div class="img-box-description">
                <p>
                  Stable hash power is supplied by securing sufficient reserved
                  hash power in consideration of the lifespan of the miner
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 my-3">
            <div class="img-box">
              <div class="img-box-img">
                <img src="img/img-feature-3.svg" />
              </div>
              <div class="img-box-title type-3">
                <h5>Low Barriers to Entry</h5>
              </div>
              <div class="img-box-description">
                <p>Any amount can participate in bitcoin mining at any time</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 my-3">
            <div class="img-box">
              <div class="img-box-img">
                <img src="img/img-feature-4.svg" />
              </div>
              <div class="img-box-title type-4">
                <h5>Liquidity for Mining Assets</h5>
              </div>
              <div class="img-box-description">
                <p>
                  Token trading makes it easy to purchase and dispose of mining
                  assets
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-sm-auto">
            <a href="/BTCTH_WhitePaper_Eng_v.1.0.2.pdf" target="blank" class="btn btn-primary btn-lg btn-block btn-shadow"
              >Read the Whitepaper</a
            >
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="main-section main-section-5" id="stat">
      <div class="container container-max-lg">
        <div class="cont-box">
          <h5>Stat of BTCTH</h5>
          <div class="row padding-1">
            <div class="col-12 col-sm-4 mb-4 mb-sm-0">
              <p class="mb-0 mb-sm-3">Total Circular Supply (BTCTH)</p>
              <h2>15,184,117</h2>
            </div>
            <div class="col-12 col-sm-4 mb-4 mb-sm-0">
              <p class="mb-0 mb-sm-3">Holders</p>
              <h2>9,184</h2>
            </div>
            <div class="col-12 col-sm-4">
              <p class="mb-0 mb-sm-3">Transaction</p>
              <h2>174,816</h2>
            </div>
          </div>
        </div>
        <div class="cont-box">
          <h5>Stat of BTC Mining</h5>
          <div class="row padding-1">
            <div class="col-12 col-sm-4 mb-4 mb-sm-0">
              <p class="mb-0 mb-sm-3">Total Value Locked (USD)</p>
              <h2>15,184,117</h2>
            </div>
            <div class="col-12 col-sm-4 mb-4 mb-sm-0">
              <p class="mb-0 mb-sm-3">Staked BTCTH</p>
              <h2>9,184</h2>
            </div>
            <div class="col-12 col-sm-4">
              <p class="mb-0 mb-sm-1">
                Cumulative Bitcoin Mining<br />(Mining Days)
              </p>
              <h2>3.7241 (65)</h2>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- <section class="main-section main-section-6" id="partners">
      <div class="container">
        <h2>Partners</h2>
        <div class="row padding-3">
          <div class="col-6 col-md-4 col-lg-3">
            <div class="patner-box">
              <img src="img/img-logo-part-talas.svg" />
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3">
            <div class="patner-box">
              <img src="img/img-logo-part-symvers.svg" />
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3">
            <div class="patner-box">
              <img src="img/img-logo-part-probit.svg" />
            </div>
          </div>
          <div class="col-6 col-md-4 col-lg-3">
            <div class="patner-box">
              <img src="img/img-logo-part-hexlant.svg" />
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="main-section main-section-7" id="mining-center">
      <div class="container">
        <div class="cont-wrap">
          <div class="img-cont">
            <img src="img/img-map-kz.svg" />
          </div>
          <div class="text-cont">
            <h2>Mining Center</h2>
            <div class="row mb-3 mb-md-4">
              <div class="col-auto">
                <img src="img/img-minngcenter-location.svg" />
              </div>
              <div class="col">
                <div class="row mt-1">
                  <div class="col-auto w-70">
                    <p class="text-16 text-700">Location</p>
                  </div>
                  <div class="col">
                    <p class="text-16">Ualikhanovskiy, Kazakhstan</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3 mb-md-4">
              <div class="col-auto">
                <img src="img/img-minngcenter-watt.svg" />
              </div>
              <div class="col">
                <div class="row mt-1">
                  <div class="col-auto w-70">
                    <p class="text-16 text-700">Wattage</p>
                  </div>
                  <div class="col">
                    <p class="text-16">Max 10,000kW</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3 mb-md-4">
              <div class="col-auto">
                <img src="img/img-minngcenter-climate.svg" />
              </div>
              <div class="col">
                <div class="row mt-1">
                  <div class="col-auto w-70">
                    <p class="text-16 text-700">Climate</p>
                  </div>
                  <div class="col">
                    <p class="text-16">
                      Dry, Avg. annual temperature of<br />21 to 26 degrees
                      Celsius
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12 col-sm-auto">
                <a href="#" class="btn btn-primary btn-lg btn-block btn-shadow"
                  >Watch Live - Data Center</a
                >
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<script>
import PrjConst from '@/mixins/prjconst.js'

export default {
  mixins: [ PrjConst ],

  name: "Home",
  props: {},
  watch: {
    $route(to, from) {
      // console.log('Route changed to :', to)
      if (to) {
        if (to.name == "home-loc") {
          if (!to.params.loc) this.scroll("home");
          else this.scroll(to.params.loc);
        } else if (to.name == "home") {
          this.scroll("home");
        }
      }
    },
  },
  mounted() {
    if (this.$route.name == 'home-loc') {
      let loc = this.$route.params.loc;
      if (loc) {
        this.scroll(loc);
      }
    }
    else if (this.$route.name == 'home-ref') {
      let refId = this.$route.params.refId;
      if (refId) {
        sessionStorage.setItem('ref', refId)
      }
    }
  },
  methods: {
    scrollToTarget(id) {
      let elem = document.getElementById(id);
      if (elem) {
        elem.scrollIntoView({
            block: "start",
            behavior: "smooth",
        });
      }
    },
    scroll(id) {
      let elem = document.getElementById(id);
      if (elem) {
        const headerOffset = 80;
        const bodyTop = document.body.getBoundingClientRect().top;
        const elementTop = elem.getBoundingClientRect().top;
        const elemPos = elementTop - bodyTop;
        let offset = elemPos - headerOffset;

        window.scrollTo({
          behavior: "smooth",
          top: offset,
        });
      }
    },
  },
};
</script>

