
const ProjectConstants = {
    // General constants
    urlPath: 'https://poc.btcth.finance/',      // should end with '/'
    network: 'ropsten',
    chainId: '0x3',     // Ropsten

    // Smart Contracts
    tokenName: 'POCT',
    rewardTokenName: 'BTCPegged',
    contractNames: {
        stakeToken: 'POCT',
        rewardToken: 'BTCPegged',
        crowdSale: 'POCTCrowdSale',
        miningFarm: 'POCTFarm',
    },

    // SNS Accounts
    twitter: 'https://twitter.com/BTCTH_official',
    telegram_chat: 'https://t.me/BTCTH_chat',
    telegram_ann: 'https://t.me/BTCTH_ann',
    medium: 'https://medium.com/@btcth_official',
    facebook: '',
    github: 'https://github.com/btcth',
}

export default ProjectConstants;